const getSlug = (name: string) => {
  if (!name) {
    return "product";
  }

  const fullSlug = name
    .toLowerCase()
    .trim()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");

  return fullSlug.substring(0, 50);
};

export const getMenuItemUrl = (
  menuItem: {
    dispensarySlug: string;
    name: string;
    menuItemId: string | number;
    variants?: Array<{ id: string | number }>;
  },
  selectedVariantId?: number,
) => {
  const variantId =
    selectedVariantId || (menuItem.variants && menuItem.variants[0].id);

  return `/dispensary-info/${menuItem.dispensarySlug}/p/${
    menuItem.menuItemId
  }/${getSlug(menuItem.name)}${variantId ? `?variant=${variantId}` : ""}`;
};

// this is a bandaid fix for https://leafly.atlassian.net/browse/NL-1565
// to unblock the shopping experience for .ca(cannabis-store) and cbd-stores
// a heavier lift will be needed to update all consuming types to be able to use this util
export const getDynamicMenuItemUrl = (
  menuItem: {
    name: string;
    menuItemId: string | number;
    variants?: Array<{ id: string | number }>;
  },
  dispensaryPath?: string,
  selectedVariantId?: number,
) => {
  const variantId =
    selectedVariantId || (menuItem.variants && menuItem.variants[0].id);

  return `${dispensaryPath}/p/${
    menuItem.menuItemId
  }/${getSlug(menuItem.name)}${variantId ? `?variant=${variantId}` : ""}`;
};
