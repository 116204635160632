import axios from "axios";

import logError from "utils/logError";

type ATBStats = {
  atbAction?: boolean;
  atbError?: string;
};

export const reportAddToBagStats = (bagArea: string, result: ATBStats) => {
  return axios({
    data: JSON.stringify(result),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `/api/metrics/add-to-bag/${bagArea}`,
  }).catch(() => {
    logError("Unable to report Add To Bag stats");
  });
};
