import { useRouter } from "next/router";

import { reportAddToBagStats } from "api/requests/reportAtbStats";

type dataDogStatsBagAreaType = {
  [key: string]: string;
};

type statsType = {
  atbAction?: boolean;
  atbError?: string;
};

// grab bag area location for DataDog reporting
const dataDogStatsBagArea: dataDogStatsBagAreaType = {
  "/dispensary-info/[slug]": "dispensary",
  "/dispensary-info/[slug]/deals": "dispensary",
  "/dispensary-info/[slug]/menu": "dispensary",
  "/dispensary-info/[slug]/p/[menuItem]/[productSlug]": "pdp",
  "/search/shop": "shop-modal",
};

export const useATBStats = () => {
  const { pathname } = useRouter();

  // if bag area is null or undefined, used the path with / replaced with - and [] removed
  // allows us to find where the unknown errors are coming from and backfill the bag area later
  const bagArea =
    dataDogStatsBagArea[pathname] ||
    pathname?.replace(/\//g, "-")?.replace(/\[|\]/g, "");

  return {
    reportATBStats: (stats: statsType) => reportAddToBagStats(bagArea, stats),
  };
};
