import React, { ComponentProps } from "react";
import Link from "next/link";

import { getDynamicMenuItemUrl } from "utils/menuItemUrl";

import Button from "components/botanic/Button";

import { AddToBagButton } from "./AddToBagButton";

type MenuItemCardActionsProps = Omit<
  ComponentProps<typeof AddToBagButton>,
  "defaultVariantId"
> & {
  viewDetailsOnClick?: () => void;
};

export const MenuItemCardActions: React.FC<MenuItemCardActionsProps> = ({
  menuItem,
  viewDetailsOnClick = () => {},
  onReservation = () => {},
  ...props
}) => {
  const menuItemUrl = getDynamicMenuItemUrl(
    menuItem,
    menuItem.dispensary.path,
    menuItem.id,
  );

  return menuItem.onlineFulfillmentEnabled ||
    menuItem.dispensary.inStoreCartEnabled ? (
    <AddToBagButton
      {...props}
      onReservation={onReservation}
      defaultVariantId={menuItem.id}
      menuItem={menuItem}
    />
  ) : (
    <Link href={menuItemUrl} passHref legacyBehavior>
      <Button
        variant="secondary"
        onClick={viewDetailsOnClick}
        href={menuItemUrl}
        data-testid="view-details-button"
        {...props}
      >
        view details
      </Button>
    </Link>
  );
};
