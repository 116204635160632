import React from "react";
import classnames from "classnames";

import CircleCheckMarkIcon from "components/Icons/circle_check_mark.svg";

type Props = {
  similarStrain?: boolean;
  isFeatured?: boolean;
  isHorizontalCard?: boolean;
  menuCardIndicators?:
    | {
        cardFlagText: string | undefined;
        showStaffPick: boolean | undefined;
      }
    | undefined;
};

const CardFlag: React.FC<Props> = ({
  menuCardIndicators,
  similarStrain = false,
  isFeatured,
  isHorizontalCard = false,
}) => {
  const showStaffPick = !isFeatured && menuCardIndicators?.showStaffPick;
  if (!menuCardIndicators?.cardFlagText && !similarStrain && !isFeatured) {
    return null;
  }

  return (
    <div className="absolute z-10 top-0 left-0 flex" data-testid="card-flag">
      <div
        className={classnames(
          "inline-flex items-center font-bold text-xs px-sm py-xs",
          {
            "text-default bg-leafly-white":
              !menuCardIndicators?.showStaffPick ||
              isFeatured ||
              isHorizontalCard,
          },
        )}
        style={{ borderBottomRightRadius: "12px" }}
      >
        {showStaffPick && (
          <span className="text-green mr-xs">
            <CircleCheckMarkIcon height="14" width="14" />
          </span>
        )}
        {similarStrain && (
          <div className="mr-xl">
            <div className="absolute mr-sm z-10">
              <span
                className="border border-default absolute"
                style={{
                  borderRadius: "50%",
                  bottom: -5,
                  height: 10,
                  width: 10,
                }}
              />
              <span
                className="border border-default absolute"
                style={{
                  borderRadius: "50%",
                  bottom: -5,
                  height: 10,
                  left: 5,
                  width: 10,
                }}
              />
            </div>
          </div>
        )}
        {isFeatured
          ? "Featured"
          : similarStrain
            ? "Similar strain"
            : menuCardIndicators?.cardFlagText}
      </div>
    </div>
  );
};

export default CardFlag;
