import React, { HTMLAttributes } from "react";
import classnames from "classnames";

import Placeholder, { Rect } from "components/Placeholder";

const MenuItemCardPlaceholder: React.FC<
  {
    /**
     * Optional className to apply to the card container
     */
    className?: string;
    /**
     * Whether to show additional placeholder section for order actions
     */
    showOrderActions?: boolean;
    /**
     * Whether to show placeholder for one menu card per row or normal card used in carousels
     */
    oneCardPerRow?: boolean;
  } & HTMLAttributes<HTMLDivElement>
> = ({ className, showOrderActions = true, oneCardPerRow, ...others }) => {
  return (
    <div
      className={classnames(
        "relative h-full w-full bg-white shadow-low rounded p-lg pt-xl",
        {
          "flex flex-col justify-between": !oneCardPerRow,
          "grid md:flex md:justify-between": oneCardPerRow,
        },
        className,
      )}
      {...others}
    >
      <>
        {oneCardPerRow ? (
          <>
            <div className="flex gap-1">
              <div>
                <Placeholder className="mx-auto" width="112" height="112">
                  <Rect width="100%" height="100%" />
                </Placeholder>
              </div>
              <div className="flex flex-col gap-sm">
                <Placeholder width="60" height="12">
                  <Rect width="100%" height="100%" />
                </Placeholder>
                <Placeholder width="60%" height="12">
                  <Rect width="100%" height="100%" />
                </Placeholder>

                <Placeholder width="65" height="12">
                  <Rect width="100%" height="100%" />
                </Placeholder>
                <Placeholder width="65" height="12">
                  <Rect width="100%" height="100%" />
                </Placeholder>
                <Placeholder width="110" height="14">
                  <Rect width="100%" height="100%" />
                </Placeholder>
              </div>
            </div>
            <div className="grid grid-cols-[109px_1fr] md:flex md:flex-col gap-sm justify-end ">
              <Placeholder width="90" height="22" className="col-start-2">
                <Rect width="100%" height="100%" />
              </Placeholder>
              {showOrderActions && (
                <Placeholder
                  width="100%"
                  height="36"
                  className="col-start-1 col-end-4"
                >
                  <Rect width="100%" height="100%" rx="16" ry="16" />
                </Placeholder>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="h-full w-full">
              <Placeholder className="mx-auto" width="112" height="112">
                <Rect width="100%" height="100%" />
              </Placeholder>
              <Placeholder width="60" height="12" className="mt-md">
                <Rect width="100%" height="100%" />
              </Placeholder>
              <Placeholder width="100%" height="22" className="mt-xs">
                <Rect width="100%" height="100%" />
              </Placeholder>
              <Placeholder width="60%" height="12" className="mt-xs">
                <Rect width="100%" height="100%" />
              </Placeholder>
              <div className="flex mt-md">
                <Placeholder width="65" height="12" className="mr-sm">
                  <Rect width="100%" height="100%" />
                </Placeholder>
                <Placeholder width="65" height="12">
                  <Rect width="100%" height="100%" />
                </Placeholder>
              </div>
              <Placeholder width="110" height="14" className="mt-md">
                <Rect width="100%" height="100%" />
              </Placeholder>
            </div>
            <div className="mt-xs">
              <Placeholder width="40" height="12">
                <Rect width="100%" height="100%" />
              </Placeholder>
              <Placeholder width="90" height="22" className="mt-sm">
                <Rect width="100%" height="100%" />
              </Placeholder>
              {showOrderActions && (
                <Placeholder width="100%" height="36" className="mt-md">
                  <Rect width="100%" height="100%" rx="16" ry="16" />
                </Placeholder>
              )}
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default MenuItemCardPlaceholder;
