import { NextRouter } from "next/router";

const mobileParamEquals = (
  router: NextRouter,
  value: string | undefined,
): boolean =>
  Array.isArray(router.query.mobile)
    ? mobileParamEquals(router, router.query.mobile[0])
    : router.query.mobile?.toLocaleLowerCase() === value;

export const hasMobileParam = (router: NextRouter): boolean =>
  hasIosParam(router) || hasAndroidParam(router);

export const hasIosParam = (router: NextRouter): boolean =>
  mobileParamEquals(router, "ios");

export const hasAndroidParam = (router: NextRouter): boolean =>
  mobileParamEquals(router, "android");
