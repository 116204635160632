import React from "react";
import classnames from "classnames";

type Props = {
  promoted: boolean;
  position?: "left" | "right";
};

const AdFlag: React.FC<Props> = ({ promoted, position = "right" }) =>
  promoted ? (
    <div
      className={classnames(
        { "left-0": position === "left", "right-0": position === "right" },
        "absolute z-10 top-0",
      )}
      data-testid="ad-flag"
    >
      <div className="text-xs px-xs m-sm rounded bg-leafly-white text-grey font-bold">
        ad
      </div>
    </div>
  ) : (
    <></>
  );

export default AdFlag;
